import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Input } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";

const Container = styled.div`
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 80px;
	padding-bottom: 80px;
	box-sizing: border-box;
	overflow: hidden;
`;

const ContainerImage = styled.div`
	width: 740px;
	height: 1110px;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
	background-image: url(/images/metal-resources/metal-market-intelligence/BlogSubscribeFormTexture.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

const ContainerOverlay = styled.div`
	width: 740px;
	height: 1110px;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 2;
	opacity: 0.9;
`;

const ContainerContent = styled.div`
	width: 100%;
	z-index: 3;
	max-width: 1160px;
	margin: 0 auto;
	position: relative;
	transition: max-height 0.15s linear;
`;

const Spacer = styled.div`
	height: 10px;
	width: 100%;
	display: block;
`;

const AlertHolder = styled.div`
	position: fixed;
	bottom: 70px;
	right: 70px;
	z-index: 999999;
	transition: visibility 0.15s linear, opacity 0.15s linear;
`;

const StaticContent = {
	en: {
		areYouReady:
			"Are you ready to take the next step and talk with a metal professional about your needs?",
		imReadyToTalk: "I'm ready to talk",
		howCanWeHelp: "How can we help?",
		firstName: "First Name",
		lastName: "Last Name",
		company: "Company",
		email: "Email",
		zip: "Zip Code",
		inquiryDetails: "Inquiry Details",
		needHelp:
			"Need help on finding the right metal solutions? Reach out to us by filling out this form. We’ll get back to you as soon as possible.",
		send: "Send",
		cancel: "Cancel",
		thankYou: "Thank you for reaching out. We will be in contact shortly.",
		inTheMeantime:
			"In the meantime, join the list of metal professionals who receive market intelligence in their inbox monthly.",
		yourEmail: "Your Email",
		subscribe: "Subscribe",
		thankYou2:
			"Thank you! Be on the lookout for important market updates from Ryerson in your inbox.",
		topic: "Topic",
	},
	fr: {
		areYouReady:
			"Êtes-vous prêt à passer à l'étape suivante et à discuter avec un professionnel de la métallurgie de vos besoins?",
		imReadyToTalk: "Je suis prêt à parler",
		howCanWeHelp: "Comment pouvons-nous vous aider?",
		firstName: "Prénom",
		lastName: "Nom de famille",
		company: "Société",
		email: "Email",
		zip: "Code postal",
		inquiryDetails: "Détails de l'enquête",
		needHelp:
			"Besoin d'aide pour trouver les bonnes solutions métalliques? Contactez-nous en remplissant ce formulaire. Nous vous répondrons dès que possible.",
		send: "Envoyer",
		cancel: "Annuler",
		thankYou: "Merci de nous avoir contactés. Nous vous contacterons prochainement.",
		inTheMeantime:
			"En attendant, rejoignez la liste des professionnels de la métallurgie qui reçoivent mensuellement des renseignements sur le marché dans leur boîte de réception.",
		yourEmail: "Votre email",
		subscribe: "Souscrire",
		thankYou2:
			"Merci! Soyez à l'affût des mises à jour importantes du marché de Ryerson dans votre boîte de réception.",
		topic: "Sujet",
	},
	es: {
		areYouReady:
			"¿Estás listo para dar el siguiente paso y hablar con un profesional del metal sobre tus necesidades?",
		imReadyToTalk: "Estoy listo para hablar",
		howCanWeHelp: "¿Cómo podemos ayudar?",
		firstName: "Nombre de pila",
		lastName: "Apellido",
		company: "Empresa",
		email: "Email",
		zip: "Código postal",
		inquiryDetails: "Detalles de la consulta",
		needHelp:
			"¿Necesitas ayuda para encontrar las soluciones metálicas adecuadas? Contáctenos completando este formulario. Nos pondremos en contacto contigo lo antes posible.",
		send: "Enviar",
		cancel: "Cancelar",
		thankYou: "Gracias por contactarnos. Nos pondremos en contacto contigo en breve.",
		inTheMeantime:
			"Mientras tanto, únase a la lista de profesionales del metal que reciben inteligencia de mercado en su bandeja de entrada mensualmente.",
		yourEmail: "Tu correo electrónico",
		subscribe: "Suscribir",
		thankYou2:
			"¡Gracias! Esté atento a las actualizaciones importantes del mercado de Ryerson en su bandeja de entrada.",
		topic: "Tema",
	},
};

export const SpecialForm: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();
	const [userIsReady, setUserIsReady] = React.useState<boolean>(false);
	const [sending, setSending] = React.useState<boolean>(false);
	const [sent, setSent] = React.useState<boolean>(false);
	const [subscribing, setSubscribing] = React.useState<boolean>(false);
	const [subscribedEmail, setSubscribedEmail] = React.useState<string>("");
	const [subscribed, setSubscribed] = React.useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [company, setCompany] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [zip, setZip] = React.useState<string>("");
	const [inquiryDetails, setInquiryDetails] = React.useState<string>("");
	const [errorMessage, setErrorMessage] = React.useState<string>("");
	const [showError, setShowError] = React.useState<boolean>(false);

	const sendContactUsForm = () => {
		setSending(true);
		if (
			email &&
			email.length > 0 &&
			firstName &&
			firstName.length > 0 &&
			lastName &&
			lastName.length > 0 &&
			company &&
			company.length > 0 &&
			zip &&
			zip.length > 0 &&
			inquiryDetails &&
			inquiryDetails.length > 0
		) {
			let details = `${String(inquiryDetails) + "\n\n"}  Sent from: ${window.location.href}`;
			let payload = {
				email: email,
				firstName: firstName,
				lastName: lastName,
				company: company,
				zip: zip,
				topic: "blogReadyToTalk",
				message: details,
			};
			axiosInstance.post("/api/common/v1/public/contact-us", payload).then((response) => {
				setSent(true);
			});
		} else {
			setErrorMessage("Please fill out all required fields.");
			setShowError(true);
			setTimeout(() => {
				setShowError(false);
			}, 6000);
			setSending(false);
		}
	};

	const subscribe = () => {
		setSubscribing(true);
		if (subscribedEmail && subscribedEmail.length > 0) {
			let payload = {
				email: subscribedEmail,
			};
			axiosInstance
				.post("/api/common/v1/public/stay-tuned", payload)
				.then((response) => {
					if (response.status === 200) {
						setSubscribed(true);
						setSubscribing(false);
					} else {
						setSubscribed(true);
						setSubscribing(false);
					}
				})
				.catch(() => {});
		} else {
			setErrorMessage("Please enter a valid email address.");
			setShowError(true);
			setTimeout(() => {
				setShowError(false);
			}, 6000);
			setSubscribing(false);
		}
	};

	return (
		<Container
			css={css`
				background-color: ${theme.colors.tertiary.background};
				@media (max-width: ${theme.breakpoints.lg}) {
					padding-top: 20px;
					padding-bottom: 20px;
				}
			`}
		>
			<ContainerImage />
			<ContainerOverlay
				css={css`
					background-color: ${theme.colors.tertiary.background};
				`}
			/>
			<Media greaterThanOrEqual="lg">
				<ContainerContent
					css={css`
						min-height: ${sent ? "150px" : "90px"};
						max-height: ${userIsReady ? (sent ? "150px" : "860px") : "90px"};
					`}
				>
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems={"center"}
						css={css`
							visibility: ${userIsReady ? "hidden" : "visible"};
							position: absolute;
							top: 0px;
							left: 50%;
							transform: translateX(-50%);
							max-width: 1160px;
							width: 100%;
							opacity: ${userIsReady ? 0 : 1};
							transition: visibility 0s, opacity 0.15s linear;
						`}
					>
						<FlexItem
							css={css`
								width: 420px;
							`}
						>
							<Typography variant="h4" type="tertiary">
								{StaticContent[language].areYouReady}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								width: 320px;
							`}
						>
							<Button
								size="lg"
								fullwidth={true}
								type="primary"
								disabled={userIsReady}
								label={StaticContent[language].imReadyToTalk}
								onClick={() => {
									setUserIsReady(true);
								}}
							/>
						</FlexItem>
					</Flex>
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems={subscribed ? "flex-start" : "center"}
						css={css`
							visibility: ${sent ? "visible" : "hidden"};
							position: absolute;
							top: 0px;
							left: 50%;
							transform: translateX(-50%);
							max-width: 1160px;
							width: 100%;
							opacity: ${sent ? 1 : 0};
							transition: visibility 0s, opacity 0.15s linear;
						`}
					>
						<FlexItem
							css={css`
								width: 420px;
							`}
						>
							<Typography
								variant="h4"
								type="tertiary"
								css={css`
									margin-bottom: 50px;
									padding-right: 50px;
									box-sizing: border-box;
								`}
							>
								{StaticContent[language].thankYou}
							</Typography>
							<Typography variant="div" type="tertiary">
								{StaticContent[language].inTheMeantime}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								width: 460px;
							`}
						>
							{!subscribed ? (
								<Input
									inputType="text"
									placeholder={StaticContent[language].yourEmail}
									type="tertiary"
									language={language}
									size="lg"
									onChange={(e) => {
										setSubscribedEmail(e.target.value);
									}}
								/>
							) : (
								<Typography
									variant="h4"
									type="tertiary"
									css={css`
										padding-right: 50px;
										box-sizing: border-box;
									`}
								>
									{StaticContent[language].thankYou2}
								</Typography>
							)}
						</FlexItem>
						<FlexItem
							css={css`
								width: 170px;
							`}
						>
							{!subscribed && (
								<Button
									size="lg"
									fullwidth={true}
									type="primary"
									disabled={subscribing}
									label={StaticContent[language].subscribe}
									onClick={() => {
										subscribe();
									}}
								/>
							)}
						</FlexItem>
					</Flex>
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						css={css`
							visibility: ${userIsReady && !sent ? "visible" : "hidden"};
							opacity: ${userIsReady && !sent ? 1 : 0};
							transition: visibility 0s, opacity 0.15s linear;
							gap: 60px;
							top: 0px;
							max-width: 1160px;
							width: 100%;
						`}
					>
						<FlexItem
							css={css`
								width: 50%;
								.select-wrapper {
									box-sizing: border-box;
								}
								.drop-down-root {
									label {
										font-family: ${theme.typography.fontFamilies.primary};
									}
								}
							`}
						>
							<Typography
								variant="h4"
								type="tertiary"
								css={css`
									margin-bottom: 30px;
								`}
							>
								{StaticContent[language].howCanWeHelp}
							</Typography>
							<Input
								type="tertiary"
								label={StaticContent[language].firstName}
								required={true}
								language={language}
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
								size="sm"
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].lastName}
								required={true}
								language={language}
								size="sm"
								onChange={(e) => {
									setLastName(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].company}
								required={true}
								language={language}
								size="sm"
								onChange={(e) => {
									setCompany(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].email}
								required={true}
								language={language}
								size="sm"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].zip}
								required={true}
								size="sm"
								language={language}
								onChange={(e) => {
									setZip(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />

							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].inquiryDetails}
								required={false}
								language={language}
								value={inquiryDetails}
								size="sm"
								inputType="textarea"
								addStyle={{
									height: "114px",
								}}
								onChange={(e) => {
									setInquiryDetails(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer
								css={css`
									height: 20px;
								`}
							/>
							<div
								css={css`
									display: inline-block;
									width: 75px;
									vertical-align: top;
									margin-right: 20px;
								`}
							>
								<Button
									disabled={sending}
									size="md"
									type="primary"
									label={StaticContent[language].send}
									onClick={() => {
										sendContactUsForm();
									}}
								/>
							</div>
							<div
								css={css`
									display: inline-block;
									width: 85px;
									vertical-align: top;
								`}
							>
								<Button
									size="md"
									type="primary"
									background={theme.colors.primary.gray}
									foreground="light"
									label={StaticContent[language].cancel}
									disabled={sending}
									onClick={() => {
										setFirstName("");
										setLastName("");
										setCompany("");
										setEmail("");
										setZip("");
										setInquiryDetails("");
										setUserIsReady(false);
									}}
								/>
							</div>
						</FlexItem>
						<FlexItem
							css={css`
								width: 50%;
							`}
						>
							<Typography
								variant="h4"
								type="tertiary"
								css={css`
									max-width: 550px;
									padding-top: 60px;
									box-sizing: border-box;
								`}
							>
								{StaticContent[language].needHelp}
							</Typography>
						</FlexItem>
					</Flex>
				</ContainerContent>
			</Media>
			<Media lessThan="lg">
				<ContainerContent
					css={css`
						min-height: 140px;
						max-height: ${userIsReady ? (sent ? "380px" : "1240px") : "320px"};
						width: 100%;
					`}
				>
					<Flex
						direction="column"
						justifyContent="flex-start"
						alignItems={"center"}
						css={css`
							visibility: ${userIsReady ? "hidden" : "visible"};
							position: absolute;
							top: 0px;
							left: 50%;
							transform: translateX(-50%);
							max-width: 1160px;
							width: 100%;
							opacity: ${userIsReady ? 0 : 1};
							transition: visibility 0s, opacity 0.15s linear;
						`}
					>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
								padding-bottom: 100px;
							`}
						>
							<Typography variant="h3" type="tertiary">
								{StaticContent[language].areYouReady}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
							`}
						>
							<Button
								size="lg"
								fullwidth={true}
								type="primary"
								disabled={userIsReady}
								label={StaticContent[language].imReadyToTalk}
								onClick={() => {
									setUserIsReady(true);
								}}
							/>
						</FlexItem>
					</Flex>
					<Flex
						direction="column"
						justifyContent="flex-start"
						alignItems={"center"}
						css={css`
							visibility: ${sent ? "visible" : "hidden"};
							position: absolute;
							top: 0px;
							left: 50%;
							transform: translateX(-50%);
							max-width: 1160px;
							width: 100%;
							opacity: ${sent ? 1 : 0};
							transition: visibility 0s, opacity 0.15s linear;
						`}
					>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
							`}
						>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									margin-bottom: 20px;
									box-sizing: border-box;
								`}
							>
								{StaticContent[language].thankYou}
							</Typography>
							<Typography
								variant="div"
								type="tertiary"
								size="md"
								color={theme.colors.primary.white}
							>
								{StaticContent[language].inTheMeantime}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
								padding-top: 40px;
							`}
						>
							{!subscribed ? (
								<Input
									inputType="text"
									placeholder={StaticContent[language].yourEmail}
									type="tertiary"
									language={language}
									size="xl"
									onChange={(e) => {
										setSubscribedEmail(e.target.value);
									}}
								/>
							) : (
								<Typography
									variant="h3"
									type="tertiary"
									css={css`
										box-sizing: border-box;
									`}
								>
									{StaticContent[language].thankYou2}
								</Typography>
							)}
						</FlexItem>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
								padding-top: 20px;
							`}
						>
							{!subscribed && (
								<Button
									size="lg"
									fullwidth={true}
									type="primary"
									disabled={subscribing}
									label={StaticContent[language].subscribe}
									onClick={() => {
										subscribe();
									}}
								/>
							)}
						</FlexItem>
					</Flex>
					<Flex
						direction="column"
						justifyContent="flex-start"
						alignItems="center"
						css={css`
							visibility: ${userIsReady && !sent ? "visible" : "hidden"};
							opacity: ${userIsReady && !sent ? 1 : 0};
							transition: visibility 0s, opacity 0.15s linear;
							gap: 20px;
							top: 0px;
							max-width: 1160px;
							width: 100%;
						`}
					>
						<FlexItem
							css={css`
								width: calc(100% - 40px);
								.select-wrapper {
									box-sizing: border-box;
								}
								.drop-down-root {
									label {
										font-family: ${theme.typography.fontFamilies.primary};
									}
								}
							`}
						>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									margin-bottom: 20px;
								`}
							>
								{StaticContent[language].howCanWeHelp}
							</Typography>
							<FlexItem
								css={css`
									width: calc(100% - 40px);
								`}
							>
								<Typography
									variant="div"
									size="sm"
									type="tertiary"
									color={theme.colors.primary.white}
									css={css`
										box-sizing: border-box;
										margin-bottom: 20px;
									`}
								>
									{StaticContent[language].needHelp}
								</Typography>
							</FlexItem>
							<Input
								type="tertiary"
								label={StaticContent[language].firstName}
								required={true}
								language={language}
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
								size="xl"
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].lastName}
								required={true}
								language={language}
								size="xl"
								onChange={(e) => {
									setLastName(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].company}
								required={true}
								language={language}
								size="xl"
								onChange={(e) => {
									setCompany(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].email}
								required={true}
								language={language}
								size="xl"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />
							<Input
								type="tertiary"
								label={StaticContent[language].zip}
								required={true}
								size="xl"
								language={language}
								onChange={(e) => {
									setZip(e.target.value);
								}}
								disabled={sending}
							/>
							<Spacer />

							<Input
								type="tertiary"
								label={StaticContent[language].inquiryDetails}
								required={false}
								language={language}
								value={inquiryDetails}
								size="xl"
								inputType="textarea"
								addStyle={{
									height: "140px",
								}}
								onChange={(e) => {
									setInquiryDetails(e.target.value);
								}}
								disabled={sending}
							/>

							<Spacer
								css={css`
									height: 20px;
								`}
							/>
							<div
								css={css`
									display: block;
									width: 100%;
								`}
							>
								<Button
									disabled={sending}
									size="lg"
									fullwidth={true}
									type="primary"
									label={StaticContent[language].send}
									onClick={() => {
										sendContactUsForm();
									}}
								/>
							</div>
							<Spacer
								css={css`
									height: 20px;
								`}
							/>
							<div
								css={css`
									display: block;
									width: 100%;
								`}
							>
								<Button
									size="lg"
									fullwidth={true}
									type="primary"
									background={theme.colors.primary.gray}
									foreground="light"
									label={StaticContent[language].cancel}
									disabled={sending}
									onClick={() => {
										setFirstName("");
										setLastName("");
										setCompany("");
										setEmail("");
										setZip("");
										setInquiryDetails("");
										setUserIsReady(false);
									}}
								/>
							</div>
						</FlexItem>
					</Flex>
				</ContainerContent>
			</Media>
			<AlertHolder
				css={css`
					visibility: ${showError ? "visible" : "hidden"};
					opacity: ${showError ? 1 : 0};
				`}
			>
				<Typography
					variant="div"
					type="primary"
					css={css`
						max-width: 300px;
						height: auto;
						padding: 20px;
						box-sizing: border-box;
						background-color: ${theme.colors.primary.background};
						box-shadow: 1px 1px 4px ${theme.colors.primary.text};
						border-radius: 6px;
					`}
				>
					<span
						css={css`
							display: inline-block;
							vertical-align: middle;
							width: 18px;
							margin-right: 10px;
						`}
					>
						<Icon size="sm" icon="error" color={theme.colors.secondary.red} />
					</span>
					<span
						css={css`
							display: inline-block;
							width: calc(100% - 28px);
							vertical-align: middle;
						`}
					>
						{errorMessage}
					</span>
				</Typography>
			</AlertHolder>
		</Container>
	);
};
